<template>
  <div class="body" style="background-color: #e5e5e6">
    <div class="certificateInfo" @click="backPage">
      <el-button
        class="backBtn"
        type="primary"
        icon="el-icon-arrow-left"
        style="background-color: #65a5f3; border: none; font-size: 30px;padding: 0%"
      ></el-button>
      <span>溯源公示</span>
    </div>
    <!-- <header-box :storeInfo="storeInfo"></header-box> -->
    <div class="backgroundBox">
      <div class="info">
        <ul>
          <li>
            <span style="font-size: 17px; font-weight: bolder"
              >{{ foodName }}（{{ traceabilityInfo.weight }}公斤）</span
            >
            <span>{{ traceabilityInfo.categoryName }}</span>
          </li>
          <li style="height: 15px;">
            <span>供货商</span>
            <span>{{ traceabilityInfo.goodsOwner }}</span>
          </li>
          <li>
            <span>产地</span>
            <span>{{ traceabilityInfo.location }}</span>
          </li>
          <li>
            <span>进货时间</span>
            <span>{{ formatDate(new Date(traceabilityInfo.date)) }}</span>
          </li>
        </ul>
      </div>
      <div  class="imgBox">
        <ul>
        <li
          v-bind:class="{ borderTopLeftRadius: true }"
          @click="handleImageClick($event, traceabilityInfo.traceImg)"
        >
          <p>进货凭证：</p>
          <img
            v-if="showFlagList.traceImg"
            :src="traceabilityInfo.traceImg"
            alt=""
            @click.capture.prevent
            v-on:contextmenu.prevent
          />
          <!-- <img v-if="!showFlagList.traceImg" src="http://m.wisevege.com/foodImg/zanwu.png" class="noImg"></img> -->
           <div class="noImg">
            <span v-if="!showFlagList.traceImg">暂无</span>
           </div>
          
        </li>
        <li
          v-bind:class="{ borderTopLeftRadius: true }"
          @click="handleImageClick($event, traceabilityInfo.certificate)"
        >
          <p>合格证：</p>
          <img
            v-if="showFlagList.certificate"
            :src="traceabilityInfo.certificate"
            alt=""
            @click.capture.prevent
            v-on:contextmenu.prevent
          />
          <!-- <img v-if="!showFlagList.certificate" src="http://m.wisevege.com/foodImg/zanwu.png" class="noImg"></img> -->
          <div class="noImg">
            <span v-if="!showFlagList.certificate">暂无</span>
           </div>
        </li>
        <li
          v-bind:class="{ borderTopLeftRadius: true }"
          @click="handleImageClick($event, traceabilityInfo.quarantine)"
        >
          <p>校验检疫证明：</p>
          <img
            v-if="showFlagList.quarantine"
            :src="showFlagList.quarantine"
            alt=""
            @click.capture.prevent
            v-on:contextmenu.prevent
          />
          <!-- <img v-if="!showFlagList.quarantine" src="http://m.wisevege.com/foodImg/zanwu.png" class="noImg"></img> -->
          <div class="noImg">
            <span v-if="!showFlagList.quarantine">暂无</span>
           </div>
        </li>
      </ul>
      </div>
    
    </div>
  </div>
</template>

<script>
import globalFunctions from "../assets/js/globalFunctions";
export default {
  name: "TraceabilityDetail",
  components: {
  },
  data() {
    return {
      foodImg: "",
      foodName: "",
      storeInfo: {},
      traceabilityInfo: {},
      showFlagList: {
        traceImg: false,
        certificate: false,
        quarantine: false,
        covidTest: false,
      },
    };
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    //获取通过vue-router跳转传递的参数
    this.traceabilityInfo = JSON.parse(this.$route.query.traceability);
    this.foodImg = this.traceabilityInfo.goodsImg;
    this.foodName = this.traceabilityInfo.goodsName;
    for (let i = 0; i < Object.keys(this.showFlagList).length; i++) {
      let key = Object.keys(this.showFlagList)[i];
      if (
        this.traceabilityInfo[key] &&
        this.traceabilityInfo[key] !== "http://m.wisevege.com/foodImg/zanwu.png"
      ) {
        this.showFlagList[key] = true;
      } else {
        this.showFlagList[key] = false;
      }
    }
  },
  methods: {
    formatDate(date) {
      return globalFunctions.formatDate(date);
    },
    backPage() {
      // this.$router.back();
      this.$router.go(-1);
    },
    handleImageClick(event, imgSrc) {
      if (imgSrc && document.fullscreenElement) {
        // 判断是否已进入全屏
        const element =
          document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement;
        if (element === event.target) {
          // 如果是当前图片元素
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }
      } else {
        // 启动全屏
        if (document.fullscreenEnabled) {
          event.target.requestFullscreen();
        } else if (document.msRequestFullscreen) {
          event.target.msRequestFullscreen();
        } else if (document.mozRequestFullScreen) {
          event.target.mozRequestFullScreen();
        } else if (document.webkitRequestFullscreen) {
          event.target.webkitRequestFullscreen();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.certificateInfo {
  color: #fff;
  font-size: 40px;
  padding: 20px;
  background-color: #65a5f3;
  align-items: center;
  height: 100px;
  display: flex;
  z-index: -1;
  .bacKBtn {
    font-size: 50px;
    background: transparent;
    border: none;
    padding: 0;
  }
  span {
    width: 85%;
  }
}
.backgroundBox {
  background: #e5e5e6;
  ul {
    margin: 0;
    padding: 15px;
    list-style: none;
  }
  .info {
    border-radius: 10px;
    margin: 15px 30px 5px 30px;
    padding: 10px;
    width: 92%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 6px 6px 10px 0 rgba(25, 37, 49, 0.15);
    position: relative;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      li {
        padding: 6px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        span:last-of-type {
          display: -webkit-box;
          color: #b2b2b2;
          max-width: 70%;
          text-align: right;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
  .imgBox {
    display: flex;
    padding: 10px 30px 0px 30px;
    overflow: auto;
    height: calc(100vh - 404px); 
   ul{
    width: 100%;
    display: flex;
    height: 20%;
    background: #fff;
    padding: 0 30px;
    box-shadow: 6px 6px 10px 0 rgba(25, 37, 49, 0.15);
    border-radius: 10px;
    li {
      width: 33%;
      height: 100%;
      p {
        text-align: left;
        font-size: 24px;
        padding-top: 3px;
      }
      img {
        width: 50%;
        height: 45%;
        margin: 0px 0 0px -50px;
      }
      .noImg {
        width: 50%;
        height: 45%;
        padding: 30px 30px;
        font-size: 24px;
        color: #999;
      }
    }
   }
  
  }
}
</style>
