<template>
  <div class="certificateInfo" @click="backPage">
    <el-button
      class="backBtn"
      type="primary"
      icon="el-icon-arrow-left"
      style="
        background-color: #65a5f3;
        border: none;
        font-size: 30px;
        padding: 0%;
      "
    ></el-button>
    <span>溯源列表</span>
  </div>
  <el-alert
    :title="alertTitle"
    type="warning"
    center="true"
    :closable="false"
    v-if="alertShow"
  >
  </el-alert>
  <el-empty description="暂无数据" v-if="!haveData"></el-empty>
  <div class="traceabilityList" v-if="haveData">
    <ul class="categoryTraceabilityList" ref="container" @scroll="handleScroll">
      <li
        v-for="(item, index) in traceabilityList"
        :key="index"
        @click="
          goToPage('TraceabilityDetail', { traceability: JSON.stringify(item) })
        "
      >
        <div class="traceabilityInfo">
          <div class="foodNameAndPrice">
            <p class="foodName">{{ item.goodsName }}</p>
            <div class="foodweight">
              <p class="foodWeight">{{ item.weight }}公斤</p>
            </div>
            <div class="foodDate">
              <span>{{ formatDate(item.date) }}</span>
            </div>
          </div>
          <div class="foodSource">
            <p class="foodSource" style="margin-top: 0px">
              供货商：<span>{{ item.goodsOwner }}</span>
            </p>
          </div>
        </div>
        <div class="detailIcon">
          <span style="font-size: 15px"
            >详情<i class="el-icon-arrow-right"></i
          ></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import globalFunctions from "../assets/js/globalFunctions";
import axios from "axios";

export default {
  name: "TraceabilityList",
  components: {},
  data() {
    return {
      storeInfo: {},
      //溯源数据商品分类数组
      categoryList: [],
      //当前选中商品分类index
      activeIndex: 0,
      //当前选中商品分类对象
      categoryItem: {},
      //总页数
      totalPage: 0,
      //当前已显示的页数
      currentPage: 1,
      //全部商品溯源数据数组
      allTraceabilityList: [],
      //显示的商品溯源数据数组
      traceabilityList: [],
      //是否有溯源数据flag
      haveData: true,
      //未上传数据的天数
      alertTitle: 0,
      alertShow: false,
      displayComplete: false,
    };
  },
  methods: {
    goToPage(url, param) {
      globalFunctions.goToPage(url, param, this.$store);
    },
    backPage() {
      // this.$router.back();
      this.$router.go(-1);
    },
    formatDate(date) {
      return globalFunctions.formatDate(new Date(date));
    },
    //发送请求获取选中商品分类溯源数据
    getTraceabilityList(storeId, pageNum) {
      // console.log(pageNum)
      axios({
        url: `/api/getAllGoodsTracesByStoreId?storeId=${storeId}&pageSize=10&pageNum=${pageNum}`,
        contentType: "application/json",
        dataType: "json",
      }).then((res) => {
        if (res.status === 200 && res.data.success) {
          console.log(JSON.parse(res.data.data));
          for (let item of JSON.parse(res.data.data)) {
            this.traceabilityList.push(item);
          }
        } else {
          this.currentPage--;
        }
      });
    },
    handleScroll() {
      const container = this.$refs.container;
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getTraceabilityList(this.storeInfo.id, ++this.currentPage);
      }
    },
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    let storeId = this.storeInfo.id;

    new Promise((resolve, reject) => {
      //通过商家Id获取可查询的溯源商品分类
      axios({
        url: `/api/getAllGoodsKindsByStoreId?id=${storeId}`,
        contentType: "application/json",
        dataType: "json",
      }).then((res) => {
        //没有溯源数据页面显示空状态
        console.log(JSON.parse(res.data.data));
        if (!JSON.parse(res.data.data)) {
          this.haveData = false;
        } else {
          this.categoryList = JSON.parse(res.data.data).simples;
          // console.log(this.categoryList);
          // this.alertShow = false;
          resolve();
        }
      });
    }).then(() => {
      this.getTraceabilityList(this.storeInfo.id, this.currentPage);
    });
  },
};
</script>

<style scoped lang="scss">
.certificateInfo {
  color: #fff;
  font-size: 40px;
  padding: 20px;
  background-color: #65a5f3;
  align-items: center;
  height: 100px;
  display: flex;
  z-index: -1;
  .bacKBtn {
    // font-size: 50px;
    background: transparent;
    border: none;
    padding: 0;
  }
  span {
    width: 85%;
  }
}
.traceabilityList {
  background: #e5e5e6;
  height: calc(100vh - 160px);
  display: flex;

  ul {
    // margin: 0;
    list-style: none;

    li {
      background: #fefefe;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .categoryList {
    padding: 0;
    width: 20%;
    height: 100%;
    border-right: 1px solid #f1f1f1;
    color: #999;
    font-size: 28px /*px*/;
    overflow: auto;

    li {
      border-radius: 20% 0 0 20%;
      height: 120px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .active {
      background: #2da277;
      color: #fff;
    }
  }

  .categoryTraceabilityList {
    height: calc(100vh - 160px);
    overflow: auto;
    /*width: 80%;*/
    width: 100%;
    margin: 1px 0px 40px;
    padding: 0 20px;

    li {
      height: 140px;
      padding: 5px 0 15px 0;
      box-sizing: border-box;
      margin-top: 15px;
      align-items: center;
      border-radius: 10px;
      position: relative;
      box-shadow: 6px 6px 10px 0 rgba(25, 37, 49, 0.15);
      .imgBox {
        width: 20%;
        height: 100%;
        margin: 0 30px 0 20px;
        display: flex;
        align-items: center;
        box-shadow: 0 0 5px 1px #eee;
        border-radius: 8%;
      }

      img {
        width: 100%;
      }

      .traceabilityInfo {
        padding: 15px 30px 0px 30px;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .foodweight {
          width: 20%;
          border: 1px #afaeae solid;
          border-radius: 5px;
        }

        p {
          margin: 0;
          font-size: 28px;
          color: #7f7f7f;
          text-align: left;

          span {
            color: #7f7f7f;
          }
        }

        .foodNameAndPrice {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .foodName {
            // font-weight: bolder;
            font-size: 36px /*px*/;
            color: #101111;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .foodWeight {
            text-align: center;
            font-size: 30px /*px*/;
            color: #7f7f7f;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .foodDate {
            width: 50%;
            text-align: right;
            color: #ccc;
          }
        }

        .foodSource {
          width: 100%;
          margin: 15px 0 5px 0px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .detailIcon {
        display: flex;
        align-items: end;
        justify-content: center;
        padding-bottom: 40px;
        height: 100%;
        font-size: 40px;
        position: absolute;
        right: 10px;
        color: #999;
      }
    }
  }
}

.el-empty {
  background: #fff;
  padding: 300px 0;
}
</style>
