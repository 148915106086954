<template>
  <ul class="certificateBox">
    <li>
      <div class="certificateInfo" @click="goToPage('BusinessLicenseImg', '')">
        <span class="numAndPassTitle">营业执照：</span>
        <span
          class="numAndPassVal"
          v-if="
            this.storeInfo.businessLicense &&
            this.storeInfo.businessLicense !==
              'http://m.wisevege.com/foodImg/zanwu.png'
          "
          :src="storeInfo.businessLicense"
          alt=""
          >查看<i class="el-icon-arrow-right"></i
        ></span>
        <span v-else>暂无<i class="el-icon-arrow-right"></i></span>
      </div>
      <div
        class="certificateInfo"
        @click="goToPage('FoodSafetyImg', '')"
        style="border: none"
      >
        <span class="numAndPassTitle">食品经营许可证：</span>
        <span
          class="numAndPassVal"
          v-if="
            this.storeInfo.foodLicense ||
            this.storeInfo.foodLicense !==
              'http://m.wisevege.com/foodImg/zanwu.png'
          "
          :src="storeInfo.foodLicense"
          >查看<i class="el-icon-arrow-right"></i
        ></span>
        <span v-else>暂无<i class="el-icon-arrow-right"></i></span>
      </div>
    </li>
  </ul>
</template>

<script>
import MarketInfo from "../components/marketInfo";
import axios from "axios";
import globalFunctions from "../assets/js/globalFunctions";

export default {
  name: "Certificate",
  components: {},
  props: {
    storeInfo: Object,
  },
  data() {
    return {
      // delivery: ['无'],
      //           deliveryOptions: ['有', '无'],
      //           storeInfo: {},
      //           healthCertificate: {},
      //           healthCertificatePass: false

      storeInfo: {},
    };
  },
  props: {
    storeInfo: Object,
  },
  methods: {
    // goToPage(url, param) {
    // globalFunctions.goToPage(url, param, this.$store);
    // },
    goToPage(url, param) {
      if (url === "BusinessLicenseImg") {
        if (
          this.storeInfo.businessLicense &&
          this.storeInfo.businessLicense !==
            "http://m.wisevege.com/foodImg/zanwu.png"
        ) {
          globalFunctions.goToPage(url, param, this.$store);
        }
      } else if (url === "FoodSafetyImg") {
        if (
          this.storeInfo.foodLicense &&
          this.storeInfo.foodLicense !==
            "http://m.wisevege.com/foodImg/zanwu.png"
        ) {
          globalFunctions.goToPage(url, param, this.$store);
        }
      }
    },
  },
  watch: {
    storeInfo: function (newVal, oldVal) {
      // this.storeInfoData = newVal;
      // console.log(this.storeInfoData);
      axios({
        url: "/api/getStoreEvaluate",
        method: "post",
        contentType: "application/x-www-form-urlencoded",
        // data: {storeId:newVal.id}
        data: `storeId=${newVal.id}`,
      }).then((res) => {
        // console.log(res);
        this.storeRateValue = res.data.data;
      });
    },
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    console.log(this.storeInfo);
    // console.log(this.storeInfo.storeShopkeeper);
    if (this.$store.state.healthCertificateState) {
      this.healthCertificate =
        this.$store.state.healthCertificateState.certificate;
      this.healthCertificatePass = this.healthCertificate.pass;
    }
  },
};
</script>

<style scoped lang="scss">
.certificateBox {
  padding: 45px 45px 50px 45px;
  list-style: none;
  margin: 0;
  li {
    padding: 25px 30px 5px 30px;
    background: #fff;
    border-radius: 10px;
    .certificateInfo {
      align-items: center;
      margin-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      text-align: left;
      height: 75px;
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      p {
        margin: 0;
      }
      .certificateName {
        font-size: 32px /*px*/;
        color: #333;
      }
      .numAndPassTitle,
      .numAndPassVal {
        font-size: 28px /*px*/;
      }
      .numAndPassVal {
        color: #b2b2b2;
      }
      .pass {
        color: #fa4459;
      }
      .notPass {
        color: #00cf3f;
      }

      &.food-safety {
        .certificateName {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .history-food-safety {
            font-size: 24px /*px*/;
            color: #4285f4;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px /*no*/ solid #eee;
    }
    .noImg {
      display: inline-block;
      font-size: 28px;
      color: #999;
    }
  }
  li {
    margin-bottom: 25px;
  }
  li:last-of-type {
    margin-bottom: 0;
  }
}
</style>
