<template>
  <div class="certificateBox">
    <div>
        <div class="certificateInfo" @click="backPage">
          <el-button
            class="backBtn"
            type="primary"
            icon="el-icon-arrow-left"
             style="background-color: #65A5F3;border: none;font-size: 30px;padding: 0%;"
          ></el-button>
          <span>食品经营许可证</span>
        </div>
        <img
        v-if="
          this.storeInfo.foodLicense
          &&
          this.storeInfo.foodLicense
          !==
            'http://m.wisevege.com/foodImg/zanwu.png'
        "
        :src="storeInfo.foodLicense
"
        alt=""
      />
      <img
        v-if="
          !this.storeInfo.foodLicense
          ||
          this.storeInfo.foodLicense
          ===
            'http://m.wisevege.com/foodImg/zanwu.png'
        "
         src="http://m.wisevege.com/foodImg/zanwu.png"
        class="noImg"
        />
    </div>
  </div>
</template>

<script>
import MarketInfo from "../components/marketInfo";
import axios from "axios";
import globalFunctions from "../assets/js/globalFunctions";

export default {
  name: "BusinessLicenseImg",
  components: {
    MarketInfo,
  },
  data() {
    return {
      delivery: ["无"],
      deliveryOptions: ["有", "无"],
      storeInfo: {},
      healthCertificate: {},
      healthCertificatePass: false,
    };
  },
  methods: {
    goToPage(url, param) {
      globalFunctions.goToPage(url, param, this.$store);
    },
    backPage() {
      // this.$router.back();
      this.$router.go(-1);
    },
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    if (this.$store.state.healthCertificateState) {
      this.healthCertificate =
        this.$store.state.healthCertificateState.certificate;
      this.healthCertificatePass = this.healthCertificate.pass;
    }
  },
};
</script>

<style scoped lang="scss">
.certificateBox {
  background-color: #E5E5E6;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  .certificateInfo {
    color: #fff;
    font-size: 40px;
    padding: 20px;
    background-color: #65a5f3;
    align-items: center;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    z-index: -1;
    .bacKBtn {
      font-size: 50px;
      background: transparent;
      border: none;
      padding: 0;
    }
    span {
      width: 85%;
    }
    &.food-safety {
      .certificateName {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .history-food-safety {
          font-size: 24px /*px*/;
          color: #4285f4;
        }
      }
    }
  }
  div {
    // padding: 30px;
    background: #fff;
    // border-radius: 10px;

    img {
      padding: 20px 10px 0px 10px;
      width: 95%;
      // border-radius: 10px;
      // border: 1px/*no*/ solid #eee;
    }
    .noImg {
      padding: 20px 10px 0px 10px;
      width: 50%;
      margin-top: 50%;
 height: 100%;
    }
  }
  div {
    margin-bottom: 25px;
  }
  div:last-of-type {
    margin-bottom: 0;
  }
}
</style>
