<template>
  <div class="certificateInfo" @click="backPage">
    <el-button
      class="backBtn"
      type="primary"
      icon="el-icon-arrow-left"
      style="background-color: #65a5f3; border: none; font-size: 30px;padding: 0%;"
    ></el-button>
    <span>餐具消毒处理列表</span>
  </div>
  <el-alert
    :title="alertTitle"
    type="warning"
    center="true"
    :closable="false"
    v-if="alertShow"
  >
  </el-alert>
  <el-empty description="暂无数据" v-if="!haveData"></el-empty>
  <div class="traceabilityList" v-if="haveData">
    <ul class="categoryTraceabilityList" ref="container" @scroll="handleScroll">
      <li
        v-for="(item, index) in traceabilityList"
        :key="index"
        :class="{ expanded: !isExpanded[index] }"
      >
        <div class="traceabilityInfo">
          <div v-show="!isExpanded[index]">
            <div>
              <div class="foodNameAndPrice">
                <p class="foodName">{{ item.articles_name }}</p>
                <div class="foodweight" style="margin-bottom: 2px;">
                  <p class="foodWeight">
                    {{ item.articles_number }}{{ item.articles_unit }}
                  </p>
                </div>
                <div class="foodDate">
                  <span>{{ formatDate(item.start_time) }}</span>
                </div>
              </div>
              <div  class="foodbody" style="width: 100%; height: 100%; display: flex;">
                <div class="foodleft" style="width: 80%;">
                  <p class="foodSource">
                {{ item.disinfection_method_name }}：<span
                  >{{ item.disinfection_duration }}分钟</span
                >
              </p>
                </div>

              <div class="detailIcon" @click="toggleExpansion(index,item)">
                <span
                  :class="{ 'expanded-icon': isExpanded[index] && item.disinfection_picture !== 'http://m.wisevege.com/foodImg/zanwu.png'}"
                  v-if="!isExpanded[index]"
                >
                  <i class="el-icon-arrow-down"></i>
                </span>
                <span v-else="">
                  <i class="el-icon-arrow-up"></i>
                </span>
              </div>
              </div>

            </div>
          </div>
        </div>
        <!-- 展开内容 -->
        <div v-show="isExpanded[index]" class="expandedContent">
          <div class="expandbody" style="margin-left: 30px;">
            <div
              style="
                /* margin-top: -7%; */
                margin-top: 20px;
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <p
                style="
                  font-size: 20px;
                  color: #101111;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  /* margin-left: 30px; */
                  margin: 0 0 10px 0px;
                "
              >
                {{ item.articles_name }}
              </p>
              <div style="width: 20%;">
                <p
                  style="
                    /* height: 20px; */
                    height: 20px;
                    font-size: 15px;
                    text-align: center;
                    border: 1px #afaeae solid;
                    border-radius: 3px;
                    color: #7f7f7f;
                    margin: 0 0 10px 0px;
                  "
                >
                  {{ item.articles_number }}{{ item.articles_unit }}
                </p>
              </div>
              <div style="width: 45%;color: #ccc; margin: 0 0 10px 0px;">
                <span style="font-size: 12px; margin-left: 60%">{{
                  formatDate(item.start_time)
                }}</span>
              </div>
            </div>
            <div class="expandmain" style="width: 100%;display: flex;" >
              <div class="expandleft" style="width: 80%;">
                <p
                style="
                  color: #7f7f7f;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  /* margin: 0 0 0 1%; */
                  margin: 0%;
                  /* padding: 0 0 10px 0; */
                  font-size: 15px;
                  text-align: left;
                  /* border: 1px #7f7f7f solid; */
                "
              >
                {{ item.disinfection_method_name }}：<span
                  >{{ item.disinfection_duration }}分钟</span
                >
              </p>
              <div
              class="imgBox"
              @click="handleImageClick($event, item.disinfection_picture)"
            >
              <img
                @click.capture.prevent
                v-on:contextmenu.prevent
                v-if="
                  item.disinfection_picture &&
                  item.disinfection_picture !==
                    'http://m.wisevege.com/foodImg/zanwu.png'
                "
                :src="item.disinfection_picture"
                alt=""
              />
              <img
                v-if="
                  !item.disinfection_picture ||
                  item.disinfection_picture ===
                    'http://m.wisevege.com/foodImg/zanwu.png'
                "
                src="http://m.wisevege.com/foodImg/zanwu.png"
                class="noImg"
                />
            </div>
              </div>
              <div class="detailIconT" @click="toggleExpansion(index,item)">
              <span
                :class="{ 'expanded-icon': isExpanded[index] && item.disinfection_picture !== 'http://m.wisevege.com/foodImg/zanwu.png' }"
                v-if="!isExpanded[index]"
              >
                <i class="el-icon-arrow-down"></i>
              </span>
              <span v-else>
                <i class="el-icon-arrow-up"></i>
              </span>
            </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import globalFunctions from "../assets/js/globalFunctions";
import axios from "axios";

export default {
  name: "CutleryDisinfection",
  components: {},
  data() {
    return {
      storeInfo: {},
      //溯源数据商品分类数组
      categoryList: [],
      //当前选中商品分类index
      activeIndex: 0,
      //当前选中商品分类对象
      categoryItem: {},
      //总页数
      totalPage: 0,
      //当前已显示的页数
      currentPage: 1,
      //全部商品溯源数据数组
      allTraceabilityList: [],
      //显示厨余垃圾数据数组
      traceabilityList: [],
      isExpanded: [], // 初始化所有元素为未展开
      //是否有溯源数据flag
      haveData: true,
      //未上传数据的天数
      alertTitle: 0,
      alertShow: false,
      displayComplete: false,
    };
  },
  methods: {
    handleImageClick(event, imgSrc) {
      if (imgSrc && document.fullscreenElement) {
        // 判断是否已进入全屏
        const element =
          document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement;
        if (element === event.target) {
          // 当前图片元素
          if (document.exitFullscreen) {
            // 最新浏览器
            document.exitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }
      } else {
        // 启动全屏
        if (document.fullscreenEnabled) {
          event.target.requestFullscreen();
        } else if (document.msRequestFullscreen) {
          event.target.msRequestFullscreen();
        } else if (document.mozRequestFullScreen) {
          event.target.mozRequestFullScreen();
        } else if (document.webkitRequestFullscreen) {
          event.target.webkitRequestFullscreen();
        }
      }
    },
    toggleExpansion(index, item) {
  if (!item.disinfection_picture ||  item.disinfection_picture === 'http://m.wisevege.com/foodImg/zanwu.png') {
    this.isExpanded[index] = false;
    // confirm("没有餐具消毒图片！")
    alert("没有检查图片！");
  } else {
    this.isExpanded[index] = !this.isExpanded[index];
  }
},
    backPage() {
      this.$router.go(-1);
    },
    formatDate(date) {
      return globalFunctions.formatDate(new Date(date));
    },
    getTraceabilityList(company_id, pageNum) {
      axios({
        // /api/wise/report/tbMorningChecklist/queryTbMorningChecklist?company_id=15&keywords=&inspection_date=2023-01-01%2C2023-12-31&personal_health_status=&personal_hygiene=&page=1&perPage=10
        url: `/api/wise/report/tbTablewareDisinfection/queryTbTablewareDisinfection?company_id=${company_id}&keywords=&start_time=2023-01-01%2C2023-12-31&disinfection_method=&page=${pageNum}&perPage=10`,
        dataType: "json",
      }).then((res) => {
        console.log(res.data.data.list);
        if (res.status === 200 && res.data.message === "success") {
          // console.log(1111111);
          for (let item of res.data.data.list) {
            this.traceabilityList.push(item);
          }
        } else {
          this.currentPage--;
        }
      });
    },
    handleScroll() {
      const container = this.$refs.container;
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getTraceabilityList(this.storeInfo.id, ++this.currentPage);
      }
    },
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    console.log(this.storeInfo);
    // let company_id=this.storeInfo.id;
    new Promise((resolve, reject) => {
      //通过商家Id获取可查询的溯源商品分类
      axios({
        // url: `/api/getAllGoodsKindsByStoreId?id=${storeId}`,
        url: `/api/wise/report/public/getEnumByParentName?name=消毒方式`,
        // url: `/api/wise/report/tbGarbageRecycle/getTbGarbageRecycleByAllQuery?company_id=${company_id}&keywords=&garbage_property=&recycle_date=2023-01-01%2C2023-12-31&page=1&perPage=10`,
        contentType: "application/json",
        dataType: "json",
      }).then((res) => {
        //没有溯源数据页面显示空状态
        // console.log(res.data.data.list);
        console.log(res.data.data);
        if (!res.data.data) {
          this.haveData = false;
        } else {
          this.categoryList = res.data.data.options;
          console.log(this.categoryList);
          resolve();
        }
      });
    }).then(() => {
      this.getTraceabilityList(this.storeInfo.id, this.currentPage);
    });
  },
};
</script>

<style scoped lang="scss">
.expanded {
  /* 定义展开时内容区域的高度 */
  scrollbar-width: none; /* Firefox */

  // overflow-y: auto;
  max-height: 130px;
  transition: max-height 0.3s ease;
}

.expanded-icon {
  /* 改变图标方向 */
  transform: rotate(180deg);
}
.expandedContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: -112%;
  .detailIconT {
        width: 20%;
        height: 28%;
        display: flex;
        align-items: end;
        justify-content: center;
        // justify-content: space-between;
        font-size: 40px;
        position: absolute;
        right: 10px;
        color: #999;
      }
  // margin-left: -895px;
}
.certificateInfo {
  color: #fff;
  font-size: 40px;
  padding: 20px;
  background-color: #65a5f3;
  align-items: center;
  height: 100px;
  display: flex;
  z-index: -1;
  .bacKBtn {
    font-size: 50px;
    background: transparent;
    border: none;
  }
  span {
    width: 85%;
  }
}
.traceabilityList {
  background: #e5e5e6;
  height: calc(100vh - 160px);
  display: flex;

  ul {
    list-style: none;

    li {
      background: #fefefe;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .categoryList {
    padding: 0;
    width: 20%;
    height: 100%;
    border-right: 1px solid #f1f1f1;
    color: #999;
    font-size: 28px /*px*/;
    overflow: auto;

    li {
      border-radius: 20% 0 0 20%;
      height: 120px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .active {
      background: #2da277;
      color: #fff;
    }
  }

  .categoryTraceabilityList {
    height: calc(100vh - 160px);
    overflow: auto;
    width: 100%;
    margin: 1px 0px 40px;
    padding: 0 20px;
    scrollbar-width: none; /* Firefox */

    li {
      height: 230px;
      padding: 5px 0 15px 0px;
      box-sizing: border-box;
      margin-top: 15px;
      align-items: center;
      border-radius: 10px;
      position: relative;
      box-shadow: 6px 6px 10px 0 rgba(25, 37, 49, 0.15);

      .imgBox {
        width: 16%;
        height: 100px;
        margin: 5px 30px 0 10px;
        display: flex;
        align-items: center;
        border-radius: 8%;
        img {
          width: 100%;
          height: 85%;
        }
        .noImg {
          width: 16%;
          height: 85%;
        }
      }

      .traceabilityInfo {
        padding: 0 30px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .foodweight {
          width: 20%;
          border: 1px #afaeae solid;
          border-radius: 5px;
        }

        p {
          margin: 0;
          font-size: 28px;
          color: #7f7f7f;
          padding: 0 0 5px 0;
          text-align: left;
          span {
            color: #7f7f7f;
          }
        }

        .foodNameAndPrice {
          margin-top: 1%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .foodName {
            font-size: 36px /*px*/;
            color: #101111;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .foodWeight {
            height: 30px;
            text-align: center;
            font-size: 30px /*px*/;
            color: #7f7f7f;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .foodDate {
            width: 35%;
            text-align: right;
            color: #ccc;
          }
        }

        .foodSource {
          margin: 10px 0 5px 0px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .detailIcon {
        width: 20%;
        height: 35%;
        display: flex;
        align-items: end;
        justify-content: center;
        // justify-content: space-between;
        font-size: 40px;
        position: absolute;
        right: 10px;
        color: #999;
      }
    }
  }
}

.el-empty {
  background: #fff;
  padding: 300px 0;
}
</style>
