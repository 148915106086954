<template>
  <div class="headBg"></div>
  <ul class="certificateBox">
    <div class="storeTitle">
      <el-button
        class="backBtn"
        type="primary"
        icon="el-icon-arrow-left"
        @click="backPage"
      ></el-button>
      <img src="../assets/img/组35.png" alt="" />
      <div class="nameAndRate">
        <p class="storename">{{ storeInfo.storeName }}</p>
        <p class="storeinfo">
          {{ storeInfo.marketName }}/{{ storeInfo.storeCategory }}/{{
            storeInfo.boothNum
          }}
        </p>
      </div>
    </div>
    <li>
      <div class="certificateInfo">
        <span class="numAndPassTitle">联系人：</span>
        <span class="numAndPassValT"
          ><span style="color: #7f7f7f">{{ storeInfo.storeShopkeeper }}</span
          >（{{ storeInfo.storeShopkeeperTel }}）</span
        >
      </div>
      <div
        class="certificateInfo"
        style="border: none"
        :style="{ border: 'none' }"
        @click="goToPage('HealthSafetyImg', '')"
      >
        <span class="numAndPassTitle">健康证：</span>
        <span
          class="numAndPassVal"
          v-if="
            this.healthCertificate.healthCertificateImage &&
            this.healthCertificate.healthCertificateImage !==
              'http://m.wisevege.com/foodImg/zanwu.png'
          "
          :src="this.healthCertificate.healthCertificateImage"
          alt=""
          :class="
            this.healthCertificate.healthCertificateImage
              ? this.healthCertificatePass
                ? 'pass'
                : 'notPass'
              : ''
          "
          >{{
            this.healthCertificate.healthCertificateImage
              ? this.healthCertificatePass
                ? "已过期"
                : "未过期"
              : "暂无"
          }}
          <i class="el-icon-arrow-right"></i
            >
    </span>
    <span
          class="numAndPassValT"
          v-if="
            !this.healthCertificate.healthCertificateImage || this.healthCertificate.healthCertificateImage === 'http://m.wisevege.com/foodImg/zanwu.png'
          "
          >暂无<i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div></div>
    </li>
    <li>
      <div class="certificateInfo" @click="goToPage('BusinessLicenseImg', '')">
        <span class="numAndPassTitle">营业执照：</span>
        <span
          class="numAndPassValT"
          v-if="
            storeInfo.businessLicense &&
            storeInfo.businessLicense !==
              'http://m.wisevege.com/foodImg/zanwu.png'
          "
          :src="storeInfo.businessLicense"
          alt=""
          >查看<i class="el-icon-arrow-right"></i
        ></span>
        <span
          class="numAndPassValT"
          v-if="
            !storeInfo.businessLicense ||
            storeInfo.businessLicense ===
              'http://m.wisevege.com/foodImg/zanwu.png'
          "
          >暂无<i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div
        class="certificateInfo"
        @click="goToPage('FoodSafetyImg', '')"
        style="border: none"
      >
        <span class="numAndPassTitle">食品经营许可证：</span>
        <span
          class="numAndPassValT"
          v-if="
            storeInfo.foodLicense &&
            storeInfo.foodLicense !== 'http://m.wisevege.com/foodImg/zanwu.png'
          "
          >查看<i class="el-icon-arrow-right"></i
        ></span>
        <span
          class="numAndPassValT"
          v-if="
            !storeInfo.foodLicense ||
            storeInfo.foodLicense === 'http://m.wisevege.com/foodImg/zanwu.png'
          "
          :src="storeInfo.foodLicense"
          >暂无<i class="el-icon-arrow-right"></i
        ></span>
      </div>
    </li>
  </ul>
</template>

<script>
import { ElNotification } from "element-plus";
import axios from "axios";
import globalFunctions from "../assets/js/globalFunctions";
export default {
  name: "headerBox",
  data() {
    return {
      storeRateValue: 0,
      userRate: "",
      drawer: false,
      opinion: "",
      storeInfoData: "",
      healthCertificate: {},
      healthCertificatePass: false,
      delivery: ["无"],
      deliveryOptions: ["有", "无"],
    };
  },
  props: {
    storeInfo: Object,
  },
  methods: {
    backPage() {
      // this.$router.back();
      this.$router.go(-1);
    },
    goToPage(url, param) {
      const storeInfo = this.$store.state.storeInfo;
      if (url === "BusinessLicenseImg") {
        if (
          storeInfo.businessLicense &&
          storeInfo.businessLicense !==
            "http://m.wisevege.com/foodImg/zanwu.png"
        ) {
          globalFunctions.goToPage(url, param, this.$store);
        } else {
          // confirm("证件暂未上传！")
          alert("证件暂未上传！");
        }
      } else if (url === "FoodSafetyImg") {
        if (
          storeInfo.foodLicense &&
          storeInfo.foodLicense !== "http://m.wisevege.com/foodImg/zanwu.png"
        ) {
          globalFunctions.goToPage(url, param, this.$store);
        } else {
          // confirm("证件暂未上传！")
          alert("证件暂未上传！");
        }
      } else if (url === "HealthSafetyImg") {
        if (
          this.healthCertificate.healthCertificateImage &&
          this.healthCertificate.healthCertificateImage !==
            "http://m.wisevege.com/foodImg/zanwu.png"
        ) {
          globalFunctions.goToPage(url, param, this.$store);
        } else {
          alert("证件暂未上传！");
        }
      }
    },
  },
  watch: {
    storeInfo: function (newVal, oldVal) {
      // this.storeInfoData = newVal;
      // console.log(this.storeInfoData);
      axios({
        url: "/api/getStoreEvaluate",
        method: "post",
        contentType: "application/x-www-form-urlencoded",
        // data: {storeId:newVal.id}
        data: `storeId=${newVal.id}`,
      }).then((res) => {
        this.storeRateValue = res.data.data;
        axios({
          url: `/api/health/queryNewestHealthCertificate?storeId=${this.storeInfo.id}`,
          method: "get",
        }).then((res) => {
          console.log(1111111111111111)
          console.log(JSON.parse(res.data.data))
          this.healthCertificateState = JSON.parse(res.data.data);
          this.$store.state.healthCertificateState = this.healthCertificateState;
          if (this.$store.state.healthCertificateState) {
            this.healthCertificate =
              this.$store.state.healthCertificateState.certificate;
            this.healthCertificatePass = this.healthCertificate.pass;
          }
        });
      });
    },
  },

  // mounted() {
  //   // console.log(this.storeInfo);
  //   // console.log(22222222222222222222222222);
  //   if (this.$store.state.healthCertificateState) {
  //     this.healthCertificate =
  //       this.$store.state.healthCertificateState.certificate;
  //     this.healthCertificatePass = this.healthCertificate.pass;
  //   }
  // },

};
</script>

<style scoped lang="scss">
.headBg {
  width: 100%;
  // min-height: 1760px;
  height: 100%;
  background: #65a5f3;
  position: absolute;
  z-index: -1;
}

.certificateBox {
  padding: 30px 45px 0px 45px;
  list-style: none;
  margin: 0;
  li {
    padding: 25px 30px 5px 30px;
    background: #fff;
    border-radius: 10px;
    .certificateInfo {
      // text-align: center;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: 1px solid #e5e5e5;
      text-align: left;
      height: 70px;
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      p {
        margin: 0;
      }
      .certificateName {
        font-size: 32px /*px*/;
        color: #333;
      }
      .numAndPassTitle,
      .numAndPassVal {
        font-size: 28px /*px*/;
      }
      .numAndPassValT {
        font-size: 28px /*px*/;
      }
      .numAndPassVal {
        color: #b2b2b2;
      }
      .pass {
        color: #fa4459;
      }
      .notPass {
        color: #00cf3f;
      }
      // .el-icon-arrow-right{
      //   color: #b2b2b2;
      // }
      .numAndPassValT {
        color: #b2b2b2;
      }

      &.food-safety {
        .certificateName {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .history-food-safety {
            font-size: 24px /*px*/;
            color: #4285f4;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px /*no*/ solid #eee;
    }
    .noImg {
      display: inline-block;
      font-size: 28px;
      color: #999;
    }
  }
  li {
    margin-bottom: 25px;
  }
  li:last-of-type {
    margin-bottom: 0;
  }
}
.storeTitle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 10px 20px 20px 0px;
  .backBtn {
    font-size: 50px;
    background: transparent;
    border: none;
    padding: 0;
  }
  img {
    width: 85px;
    height: 85px;
    margin-left: 15px;
  }
  .nameAndRate {
    width: 70%;
    text-align: left;
    .storename {
      font-size: 40px /*px*/;
      margin: 0px 30px 0px;
      color: #fff;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .storeinfo {
      font-size: 25px /*px*/;
      margin: 10px 30px 0px;
      color: #fff;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
