<template>
  <!-- <market-info :storeInfo="storeInfo"></market-info> -->
  <div class="certificateBox">
    <div>
      <div class="certificateInfo" @click="backPage">
        <el-button
          class="backBtn"
          type="primary"
          icon="el-icon-arrow-left"
          style="background-color: #65a5f3; border: none; font-size: 30px;padding: 0%;"
        ></el-button>
        <span>营业执照</span>
      </div>
      <img
        v-if="
          this.storeInfo.businessLicense &&
          this.storeInfo.businessLicense !==
            'http://m.wisevege.com/foodImg/zanwu.png'
        "
        :src="storeInfo.businessLicense"
        alt=""
      />
      <img
        v-if="
          !this.storeInfo.businessLicense ||
          this.storeInfo.businessLicense ===
            'http://m.wisevege.com/foodImg/zanwu.png'
        "
        src="http://m.wisevege.com/foodImg/zanwu.png"
        class="noImg"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import globalFunctions from "../assets/js/globalFunctions";

export default {
  name: "BusinessLicenseImg",
  components: {
  },
  data() {
    return {
      delivery: ["无"],
      deliveryOptions: ["有", "无"],
      storeInfo: {},
      healthCertificate: {},
      healthCertificatePass: false,
    };
  },
  methods: {
    goToPage(url, param) {
      globalFunctions.goToPage(url, param, this.$store);
    },
    backPage() {
      // this.$router.back();
      this.$router.go(-1);
    },
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    if (this.$store.state.healthCertificateState) {
      this.healthCertificate =
        this.$store.state.healthCertificateState.certificate;
      this.healthCertificatePass = this.healthCertificate.pass;
    }
  },
};
</script>

<style scoped lang="scss">
.certificateBox {
  background-color: #e5e5e6;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  .certificateInfo {
    color: #fff;
    font-size: 40px;
    padding: 20px;
    background-color: #65a5f3;
    align-items: center;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    z-index: -1;
    .bacKBtn {
      font-size: 50px;
      background: transparent;
      border: none;
      padding: 0;
    }
    span {
      width: 85%;
    }
    &.food-safety {
      .certificateName {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .history-food-safety {
          font-size: 24px /*px*/;
          color: #4285f4;
        }
      }
    }
  }
  div {
    background: #fff;
    img {
      padding: 20px 10px 0px 10px;
      width: 95%;
    }
    .noImg {
      padding: 20px 10px 0px 10px;
      width: 50%;
      margin-top: 50%;
      height: 100%;
    }
  }
  div {
    margin-bottom: 25px;
  }
  div:last-of-type {
    margin-bottom: 0;
  }
}
</style>
