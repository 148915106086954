<template>
  <header-box :storeInfo="storeInfo"></header-box>
  <ul class="certificateBox" >
    <li v-if="type == undefined">
      <div
        class="certificateInfo"
        @click="goToPage('TraceabilityList', '')"
      >
        <span class="numAndPassTitle">溯源记录公示：</span>
        <span class="numAndPassVal"
          >查看<i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div
        class="certificateInfo"
        @click="goToPage('MonitoringPublicity', '')"
         style="border: none"
      >
        <span class="numAndPassTitle">快检记录公示：</span>
        <span class="numAndPassVal"
          >查看<i class="el-icon-arrow-right"></i
        ></span>
      </div>
    </li>
    <li v-else>
      <div class="certificateInfo" @click="goToPage('KitchenGarbage', '')">
        <span class="numAndPassTitle">厨余垃圾处理记录：</span>
        <span class="numAndPassVal"
          >查看<i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div class="certificateInfo" @click="goToPage('MorningInspection', '')">
        <span class="numAndPassTitle">晨检记录：</span>
        <span class="numAndPassVal"
          >查看<i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div
        class="certificateInfo"
        @click="goToPage('CutleryDisinfection', '')"
        style="border: none"
      >
        <span class="numAndPassTitle">餐具消毒记录：</span>
        <span class="numAndPassVal"
          >查看<i class="el-icon-arrow-right"></i
        ></span>
      </div>
    </li>
  </ul>
</template>
<script>
import headerBox from "../components/headerBox";
import Certificate from "./Certificate.vue";
import marketInfo from "../components/marketInfo";
import axios from "axios";
import globalFunctions from "../assets/js/globalFunctions";

export default {
  name: "Home",
  components: {
    headerBox,
    // Certificate,
  },
  methods: {
    goToPage(url, param) {
      globalFunctions.goToPage(url, param, this.$store);
    },
    //utf-8编码转换为utf-16编码，用于通过Url获取商家信息时进行转换
    utf8to16(str) {
      var out, i, len, c;
      var char2, char3;
      out = "";
      len = str.length;
      i = 0;
      while (i < len) {
        c = str.charCodeAt(i++);
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += str.charAt(i - 1);
            break;
          case 12:
          case 13:
            // 110x xxxx 10xx xxxx
            char2 = str.charCodeAt(i++);
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
            break;
          case 14:
            // 1110 xxxx 10xx xxxx 10xx xxxx
            char2 = str.charCodeAt(i++);
            char3 = str.charCodeAt(i++);
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            );
            break;
        }
      }
      return out;
    },
  },
  mounted() {
    
    console.log(window.location.href.split("&type=")[1]);
    let storeId, company_id, storeName, requestData;
    this.type = window.location.href.split("&type=")[1];
    console.log(this.type);
    if (this.type != undefined) {
      company_id = window.location.href.split("&company_id=")[1].split("&")[0];
      requestData = `name=&phone=&storeId=${company_id}`;
    } else {
      if (window.location.href.split("&storeId=")[1]) {
        storeName = window.location.href.match(/name=(\S*)&storeId=/)[1];
        storeName = storeName.replace(/_/g, "/");
        storeName = storeName.replace(/-/g, "+");
        this.$store.state.storeName = this.utf8to16(atob(storeName));
        storeId = window.location.href.split("storeId=")[1].split("&")[0];
        requestData = `name=${
          this.$store.state.storeName.split("_")[0]
        }&phone=${
          this.$store.state.storeName.split("_")[1]
        }&storeId=${storeId}`;
      } else {
        storeName = window.location.href.split("?name=")[1];
        storeName = storeName.replace(/_/g, "/");
        storeName = storeName.replace(/-/g, "+");
        this.$store.state.storeName = this.utf8to16(atob(storeName));
        requestData = `name=${
          this.$store.state.storeName.split("_")[0]
        }&phone=${this.$store.state.storeName.split("_")[1]}`;
      }
      console.log(this.$store.state.storeName);
    }
    axios({
      url: "/api/findByPhone",
      method: "post",
      contentType: "application/x-www-form-urlencoded",
      data: requestData,
    }).then((res) => {
      this.storeInfo = res.data.data;
      console.log(this.storeInfo);
      //主体信息存入vuex以备用
      this.$store.state.storeInfo = this.storeInfo;
      axios({
        url: `/api/health/queryNewestHealthCertificate?storeId=${this.storeInfo.id}`,
        method: "get",
      }).then((res) => {
        // console.log(1111111111111111)
        // console.log(JSON.parse(res.data.data))
        this.healthCertificateState = JSON.parse(res.data.data);
        this.$store.state.healthCertificateState = this.healthCertificateState;
        // this.healthCertificatePass = JSON.parse(res.data.data).pass;
      });
    });
  },
  data() {
    return {
      //   flag: "",
      storeInfo: [],
      healthCertificateState: {},
      type: undefined,
    };
  },
};
</script>

<style scoped lang="scss">
.certificateBox {
  padding: 25px 45px 0px 45px;
  list-style: none;
  margin: 0;
  li {
    padding: 25px 30px 5px 30px;
    background: #fff;
    border-radius: 10px;
    .certificateInfo {
      // text-align: center;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: 1px solid #e5e5e5;
      text-align: left;
      height: 90px;
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      p {
        margin: 0;
      }
      .certificateName {
        font-size: 32px /*px*/;
        color: #333;
      }
      .numAndPassTitle,
      .numAndPassVal {
        font-size: 28px /*px*/;
      }
      .numAndPassVal {
        color: #b2b2b2;
      }
      .pass {
        color: #fa4459;
      }
      .notPass {
        color: #00cf3f;
      }

      &.food-safety {
        .certificateName {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .history-food-safety {
            font-size: 24px /*px*/;
            color: #4285f4;
          }
        }
      }
    }
  }
  li {
    margin-bottom: 25px;
  }
  li:last-of-type {
    margin-bottom: 0;
  }
}
</style>
