<template>
  <div class="certificateInfo" @click="backPage">
    <el-button
      class="backBtn"
      type="primary"
      icon="el-icon-arrow-left"
      style="
        background-color: #65a5f3;
        border: none;
        font-size: 30px;
        padding: 0%;
      "
    ></el-button>
    <span>检测公示</span>
  </div>
  <div class="monitoringPublicity">
    <el-config-provider :locale="locale">
      <el-date-picker
        v-model="date"
        type="date"
        placeholder="选择日期"
        :disabledDate="disabledDate"
        style="width: 100%;"
        :editable="false"
        @change="getCheckDataByDate"
        clear-icon="clearDateIcon"
      ></el-date-picker>
    </el-config-provider>
    <el-alert
      :title="alertTitle"
      type="warning"
      center="true"
      style="width: 100%;background-color: #e5e5e5;"
      :closable="false"
      v-if="alertShow"
    >
    </el-alert>
    <ul class="dataBox">
      <li>
        <el-table
          :data="tableData"
          border
          style="width: 95%;margin: 0 10px;"
          header-cell-style="background: #65a5f3; color: #fff;"
        >
          <el-table-column align="center" prop="goodsName" label="商品名称">
          </el-table-column>
          <el-table-column
            align="center"
            prop="target.detectionItem"
            label="检测项目"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="fastInspectionTargetValue"
            label="检测指标"
          >
          </el-table-column>
          <el-table-column align="center" prop="stateName" label="检测结果">
          </el-table-column>
        </el-table>
      </li>
    </ul>
  </div>
</template>

<script>
import headerBox from "../components/headerBox";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { ElConfigProvider } from "element-plus";
import globalFunctions from "../assets/js/globalFunctions";
import axios from "axios";

export default {
  name: "monitoringPublicity",
  components: {
    headerBox,
    [ElConfigProvider.name]: ElConfigProvider,
  },
  mounted() {
    this.storeInfo = this.$store.state.storeInfo;
    //初始化日期选择器本月禁止选择日期
    this.currentTime = this.getCurrentTime().split("-");
    let yyyy_mm = this.currentTime[0] + "-" + this.currentTime[1];
    // console.log(yyyy_mm)
    this.getDisabledDate(yyyy_mm);
    //禁止点击日期选择器上方年月
    let headerLabel = document.querySelectorAll(
      ".el-date-picker__header-label"
    );
    for (let item of headerLabel) {
      item.setAttribute("style", "pointer-events: none;");
    }
    //获取日期选择器切换到的年月
    this.$nextTick(() => {
      let optionalDate, labelList;
      //点击年月翻页按键
      let iconBtn = document.querySelectorAll(".el-picker-panel__icon-btn");
      for (let item of iconBtn) {
        item.addEventListener("click", (event) => {
          if (
            document.querySelectorAll(".el-picker-panel__content table")[0]
              .className === "el-date-table"
          ) {
            labelList = event.target.parentElement.querySelectorAll(
              ".el-date-picker__header-label"
            );
            optionalDate =
              parseInt(labelList[0].innerHTML) +
              "-" +
              (parseInt(labelList[1].innerHTML) > 9
                ? parseInt(labelList[1].innerHTML)
                : "0" + parseInt(labelList[1].innerHTML));
            this.getDisabledDate(optionalDate);
          }
        });
      }
      // 点击header年月
      // let headerLabel = document.querySelectorAll('.el-date-picker__header-label');
      // for (let item of headerLabel){
      //     item.addEventListener('click', () => {
      //         let elDateTable = document.querySelectorAll('.el-picker-panel__content table');
      //         for (let tableItem of elDateTable){
      //             tableItem.addEventListener('click', (event) => {
      //                 if (event.path[5].className === 'el-month-table'){
      //                     labelList = event.path[7].querySelectorAll('.el-date-picker__header-label');
      //                     optionalDate = (parseInt(labelList[0].innerHTML)) + '-'
      //                         + (parseInt(labelList[1].innerHTML) > 9 ? parseInt(labelList[1].innerHTML) :
      //                             '0' + parseInt(labelList[1].innerHTML));
      //                 }else if (event.path[4].className === 'el-year-table'){
      //                     let elMonthTable = document.querySelectorAll('.el-picker-panel__content table');
      //                     for (let monthTableItem of elMonthTable){
      //                         monthTableItem.addEventListener('click', (event) => {
      //                             labelList = event.path[7].querySelectorAll('.el-date-picker__header-label');
      //                             optionalDate = (parseInt(labelList[0].innerHTML)) + '-'
      //                                 + (parseInt(labelList[1].innerHTML) > 9 ? parseInt(labelList[1].innerHTML) :
      //                                     '0' + parseInt(labelList[1].innerHTML));
      //                         })
      //                     }
      //                 }
      //             })
      //         }
      //     })
    });
  },
  data() {
    return {
      //设置中文参数
      locale: zhCn,
      //选中的时间
      date: "请选择",
      //渲染检测数据表格的数组
      tableData: [],
      //查询出的可选日期数组
      dateList: [],
      //可选日期数组长度
      dateListLength: 0,
      storeInfo: {},
      //当前时间年月日拆分的数组，['YYYY', 'MM', 'DD']
      currentTime: [],
      //切换月份时调用查询可选日期传递的时间参数，格式YYYY-MM
      optionalDate: "",
      //未上传数据的天数
      alertTitle: 0,
      alertShow: true,
    };
  },
  methods: {
    formatDate(date) {
      return globalFunctions.formatDate(date);
    },
    backPage() {
      // this.$router.back();
      this.$router.go(-1);
    },
    //渲染不可选择日期的方法
    disabledDate(time) {
      return (
        time.getTime() > Date.now() ||
        (this.dateList.indexOf(globalFunctions.formatDate(time)) &&
          this.dateList.indexOf(globalFunctions.formatDate(time)) < 0)
      );
    },
    getCurrentTime() {
      return globalFunctions.formatDate(new Date(Date.now()));
    },
    //获取显示月份可选日期的方法
    getDisabledDate(yyyy_mm) {
      // console.log(this.storeInfo)
      axios({
        url: `/api/queryFastInspectionDataByStoreId?storeId=${this.storeInfo.id}&testtm=${yyyy_mm}`,
        method: "POST",
        dataType: "json",
      }).then((res) => {
        if (res.status === 200 && res.data.success) {
          // console.log(JSON.parse(JSON.parse(res.data.data)).hasDataDays)
          // console.log(JSON.parse(res.data.data).hasDataDays)
          this.dateList = JSON.parse(res.data.data).hasDataDays;

          this.dateListLength = this.dateList.length;
          if (this.dateListLength !== 0) {
            this.date = this.dateList[0];
            this.getCheckDataByDate(this.date);
            this.alertShow = false;
          }
          // else{
          //     if (JSON.parse(res.data.data).noDataDays >= 15){
          //         this.alertTitle = "近15天内未上传检测数据！";
          //     }
          //     // else if(JSON.parse(res.data.data).noDataDays >= 3 && JSON.parse(res.data.data).noDataDays < 15){
          //     //     this.alertTitle = "近" + JSON.parse(res.data.data).noDataDays + "天内未上传检测数据！";
          //     // }
          //     else{
          //         this.alertShow = false;
          //     }
          // }
        }
      });
    },
    //获取选中日期检测数据的方法
    getCheckDataByDate(date) {
      axios({
        url: `/api/queryFastInspectionDataByStoreId?storeId=${this.storeInfo.id}
                    &testtm=${
                      typeof date !== "string" ? this.formatDate(date) : date
                    }`,
        method: "POST",
        dataType: "json",
      }).then((res) => {
        this.tableData = JSON.parse(res.data.data);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.certificateInfo {
  color: #fff;
  font-size: 40px;
  padding: 20px;
  background-color: #65a5f3;
  align-items: center;
  height: 100px;
  display: flex;
  z-index: -1;
  .bacKBtn {
    // font-size: 50px;
    background: transparent;
    border: none;
    padding: 0;
  }
  span {
    width: 85%;
  }
}
.monitoringPublicity {
  background: #e5e5e6;
  height: calc(100vh - 160px);
  padding-top: 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .clearDateIcon {
    display: none;
  }
  .dateChoose {
    height: 90px;
    background: #fff;
    padding: 0 30px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dataBox {
    background: #e5e5e6;
    li {
      p {
        margin: 10px 0 0 0;
        text-align: left;
        font-size: 24px;
        padding: 30px;
        color: #999;
      }
    }
  }
}
</style>
